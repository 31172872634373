// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfrage-js": () => import("./../../../src/pages/anfrage.js" /* webpackChunkName: "component---src-pages-anfrage-js" */),
  "component---src-pages-attractions-js": () => import("./../../../src/pages/attractions.js" /* webpackChunkName: "component---src-pages-attractions-js" */),
  "component---src-pages-buchung-js": () => import("./../../../src/pages/buchung.js" /* webpackChunkName: "component---src-pages-buchung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gallerie-js": () => import("./../../../src/pages/gallerie.js" /* webpackChunkName: "component---src-pages-gallerie-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */)
}

